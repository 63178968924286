import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import {Trans, useTranslation} from "react-i18next";
import {useCreateBeatdownPurchaseOrder} from "../../../Apis/InvoicesApi";
import {IOverviewOrderDto, OrderStatus} from "../../../Apis/Models/IOrderDto";
import useToasts from "../../../Hooks/UseToasts";
import React from "react";
import {useForm} from "react-hook-form";

type FormData = {
    orderStatus: OrderStatus;
}

interface Props {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess?: () => void;
    orders: IOverviewOrderDto[];
}
const CreateBeatdownPurchaseOrderModal = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();
    const { handleSubmit } = useForm<FormData>();

    const { mutateAsync, isLoading } = useCreateBeatdownPurchaseOrder();

    const changeOrderStatus = async () => {
        await mutateAsync({
            orderIds: props.orders.map(x => x.id),
        });

        showSuccessToast(t('purchaseOrderCreated'));
        
        props.toggleVisible();
        props.onSuccess && props.onSuccess();
    }

    return (
        <Modal title={t('createBeatdownPurchaseOrder')} visible={props.visible} onCancel={() => props.toggleVisible()} modalStyle={{overflow: 'visible'}}>
            <form onSubmit={handleSubmit(changeOrderStatus)}>
                <Space size={"large"}>

                    <Trans i18nKey={`createBeatdownPurchaseOrder`} values={{ count: props.orders.length }} />

                    <FlexRow justify={"end"}>
                        <Button type={"secondary"} buttonType={"submit"} loading={isLoading} text={t('createBeatdownPurchaseOrder')}/>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )
}

export default CreateBeatdownPurchaseOrderModal