import {IOrderDto, IOrderLogDto} from "../../../Apis/Models/IOrderDto";
import {Trans, useTranslation} from "react-i18next";
import moment from "moment";
import React from "react";
import FlexRow from "Components/FlexRow";
import styles from "./OrderLogs.module.scss";

interface IProps {
    order: IOrderDto;
}
const OrderLogs = ({ order }: IProps) => {

    const { t } = useTranslation();

    const getFields = (x: IOrderLogDto) => {
        if (!!x.fields && x.fields !== "") {
            let fields: string[] = JSON.parse(x.fields)

            return fields.map(field => t('field.' + field)).join(', ')
        }

        return "";
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const translations = [ // This is here so cli i18next can pick up the keys
        t('orderLogs.OrderUpdated'),
        
        t('orderLogs.OrderCreated'),
        t('orderLogs.OrderAcceptedInternal'),
        t('orderLogs.OrderProduced'),
        t('orderLogs.OrderDelivered'),
        t('orderLogs.OrderFinished'),
        t('orderLogs.OrderCancelled'),

        t('orderLogs.InvoiceDraftCreated'),
        t('orderLogs.OrderCreatedInClickup'),
        t('orderLogs.StockQtyUpdated'),
        t('orderLogs.ProductionNoteDownloaded'),
        t('orderLogs.BeatdownPurchaseOrderCreated'),

        t('field.OrderTitle'),
        t('field.Comment'),
        t('field.Deadline'),
        t('field.InvoicingAddress'),
        t('field.DeliveryAddress'),
        t('field.OrderLines'),
    ]

    return (
        <div>
            {order.logs?.map(x => (
                <FlexRow className={styles.orderLog} key={x.id}>
                    <div style={{width: 200}}>
                        {moment().diff(moment(x.executedAt), 'hours') < 24 ? moment(x.executedAt).fromNow() : moment(x.executedAt).format("DD-MM-YYYY HH:mm")}
                    </div>
                    <div>
                        <Trans i18nKey={`orderLogs.${x.logType}`} values={{ fields: getFields(x), userName: x.user?.name }}  />
                    </div>
                </FlexRow>
            ))}
        </div>
    )

}

export default OrderLogs