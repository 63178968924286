import {useMutation, useQuery, useQueryClient} from "react-query";
import {request} from "./BaseApi";
import {IInvoiceDto} from "./Models/IInvoiceDto";
import {saveAs} from "file-saver";
import { OrderInvoiceDto } from "./Models/OrderInvoiceDto";
import {ORDERS_CACHE_KEY} from "./OrderApi";
import {OrderStatus} from "./Models/IOrderDto";

export const ORDER_INVOICES_CACHE_KEY = 'ORDER_INVOICES_CACHE_KEY '
export const useInvoicesForOrder = (orderId: number) => {
    return useQuery<IInvoiceDto[]>([ORDER_INVOICES_CACHE_KEY, orderId], async () => {
        return await request<IInvoiceDto[]>({
            url: `/invoices/order/${orderId}`,
            method: 'GET',
        })
    });
}

export const useCheckIfOrdersHasInvoice = () => {
    return useMutation(async (ids: number[]) => {
        return await request<OrderInvoiceDto[]>({
            url: `/invoices/orders/validate`,
            method: 'POST',
            data: ids
        })
    })
}

export const useChangeOrderStatusForMultipleOrders = () => {
    const queryClient = useQueryClient();

    return useMutation(async (model: {orderIds: number[], orderStatus: OrderStatus}) => {
        return await request({
            url: `/orders/status`,
            method: 'POST',
            data: model
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}

export const useCreateBeatdownPurchaseOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(async (model: {orderIds: number[]}) => {
        return await request({
            url: `/orders/beatdown/purchaseOrder`,
            method: 'POST',
            data: model.orderIds
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}

export const useCreateInvoiceDraftForMultipleOrders = () => {
    const queryClient = useQueryClient();

    return useMutation(async (ids: number[]) => {
        return await request<IInvoiceDto>({
            url: `/invoices/orders`,
            method: 'POST',
            data: ids
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDER_INVOICES_CACHE_KEY)
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}


export const downloadInvoice = async (invoice: IInvoiceDto) => {
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/invoices/${invoice.id}/pdf`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, `Faktura - ${invoice.bookedInvoiceNumber}.pdf`);
}